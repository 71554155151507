<template>
<div class="usersList" >
  <mini-loader v-if="load" />
  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h5 class="text-dark">Все пользователи</h5>
      <a @click="createItem">
        <span class=" p-1">
          <button type="button" class="btn btn-success">Добавить
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 20 20">
              <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
            </svg>
          </button>

        </span>
      </a>
    </div>
    <table class="table table-striped" id="usersList">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Картинка </th>
          <th scope="col">Имя</th>
          <th scope="col">Телефон</th>
          <th scope="col">Статус</th>
          <th scope="col">Последний визит</th>
          <th scope="col">Действие</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, index) in data">
          <th scope="row">{{index+1}}</th>
          <td><div class="my-img-circle"> <img v-if="value.image" class="img-fluid" :src="$store.state.urlBackend + '/' + value.image" alt=""> <img class="img-fluid" v-else width="100" :src="$store.state.emptyUser" alt=""> </div></td>
          <td>{{value.name}}</td>
          <td>{{value.phone}}</td>
          <td><span class="btn btn-success" v-if="value.status == 'active'">Активный</span> <span class="btn btn-danger" v-else>Неактивный</span> </td>
          <td>{{createdAt(value.last_online_at)}}</td>
          <td>
            <a  @click="showItem(value)">
              <span class="border rounded border-primary m-1 p-2 text-primary">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                </svg>
              </span>
            </a>
            <a @click="editItem(value)">
              <span class="border rounded border-success m-1 p-2 text-success">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                </svg>
              </span>
            </a>
            <a @click="deleteItem(index, value)">
              <span class="border rounded border-danger m-1 p-2 text-danger">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg>
              </span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="m-10 text-dark text-center" style="border: 1px solid #DFE1E4; padding: 10px">1 - {{data.length}} данных  из {{totalResults}}</p>
  </div>

<!-- Show-modal -->
<a-modal v-model="visibleShowModal" title="Профиль пользователя">
  <table class="table table-striped" >
    <tbody>
      <tr>
        <td>Имя Фамилия</td>
        <td>{{user.name}}</td>
      </tr>
      <tr>
        <td>Email</td>
        <td>{{user.email}}</td>
      </tr>
      <tr>
        <td>Телефон</td>
        <td>{{user.phone}}</td>
      </tr>
      <tr>
        <td>Кто создал</td>
        <td> <span v-if="user.creator" style="color: #1327E7">{{user.creator.name}}</span> <span v-else>--</span> </td>
      </tr>
      <tr>
        <td>Последний визит</td>
        <td> {{createdAt(user.last_online_at)}} </td>
      </tr>
      <tr>
        <td>Роль</td>
        <td v-if="user.roles">
          <span v-if="user.roles.length">
            <span v-if="user.roles[0].name == 'admin'">Администратор</span>
            <span v-else-if="user.roles[0].name == 'moderator'">Модератор</span>
            <span v-else-if="user.roles[0].name == 'operation'">Оператор</span>
            <span v-else-if="user.roles[0].name == 'driver'">Водитель</span>
            <span v-else></span>
          </span>
          <span v-else>------</span>
        </td>
        <td v-else>------</td>
      </tr>
      <tr>
        <td>Дополнительное разрешение</td>
        <td v-if="user.permissions">
          <span v-if="user.permissions.length">
            <span v-if="user.permissions[0].name == 'super_admin'"> Администратор со всеми возмоностями</span>
            <span v-else-if="user.permissions[0].name == 'moderator'">Модератор с больше возможностями</span>
          </span>
          <span v-else>------</span>
        </td>
        <td v-else>------</td>
      </tr>
      <tr>
        <td>Страна</td>
        <td v-if="user.country">{{user.country.title}}</td> <td v-else>------</td>
      </tr>
      <tr>
        <td>Область</td>
        <td v-if="user.region">{{user.region.title}}</td> <td v-else>------</td>
      </tr>
      <tr>
        <td>Район или город</td>
        <td v-if="user.district">{{user.district.title}}</td> <td v-else>------</td>
      </tr>
      <tr>
        <td>Офис</td>
        <td v-if="user.branch">{{user.branch.title}}</td> <td v-else>------</td>
      </tr>
      <tr>
        <td>Статус</td>
        <td v-if="user.status == 'active'"> <span class="btn btn-success">Активный</span> </td> <td v-else> <span class="btn btn-danger">Неактивный</span> </td>
      </tr>
      <tr>
        <td>Фото: </td>
        <td>
          <div v-if="user.image">
              <img v-if="user.image" class="img-fluid" :src="$store.state.urlBackend + '/' + user.image" alt="">
          </div>
        </td>
      </tr>

      <span>

      </span>
    </tbody>
  </table>
</a-modal>

<!-- Create modal -->
<a-modal v-model="visibleCreateModal" title="Добавить нового пользователя"  footer="">
  <div class="form-group">
    <label for="exampleInputEmail">Имя Фамилия </label>
    <input type="text" class="form-control" v-model="newUser.name" :class="{'is-invalid': validateErrors.name}" placeholder="Имя">
    <div class="invalid-feedback" v-for="value in validateErrors.name"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Email </label>
    <input type="text" class="form-control" v-model="newUser.email" :class="{'is-invalid': validateErrors.email}" placeholder="Email">
    <div class="invalid-feedback" v-for="value in validateErrors.email"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Телефон </label>
    <input type="text" class="form-control" v-model="newUser.phone" :class="{'is-invalid': validateErrors.phone}" placeholder="Телефон">
    <div class="invalid-feedback" v-for="value in validateErrors.phone"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Пароль</label>
    <a-form-item :class="{'is-invalid': validateErrors.password}" >
      <a-input-password :class="{'is-invalid': validateErrors.password}" v-model="newUser.password" allow-clear placeholder="Мин 6 символов" />
    </a-form-item>
    <div class="invalid-feedback" v-for="value in validateErrors.password"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Подтверждение пароля</label>
    <a-form-item :class="{'is-invalid': validateErrors.password_confirmation}" >
      <a-input-password :class="{'is-invalid': validateErrors.password_confirmation}" v-model="newUser.password_confirmation" allow-clear placeholder="Подтвердите пароля" />
    </a-form-item>
    <div class="invalid-feedback" v-for="value in validateErrors.password_confirmation"> {{value}} </div>
  </div>

  <hr>

  <div class="form-group">
    <label for="exampleInputEmail">Роль</label>
    <a-select
      show-search
      v-model="newUser.role"
      placeholder="Роль"
      :class="{'is-invalid': validateErrors.role}"
      :default-active-first-option="false"
      style="width: 100%"
      :show-arrow="false"
      :filter-option="false"
      :not-found-content="null"
      @change="changeRole"
    >
      <a-select-option v-for="d in roles" :key="d.value">
        {{ d.title }}
      </a-select-option>
    </a-select>
    <div class="invalid-feedback" v-for="value in validateErrors.role"> {{value}} </div>
  </div>
  <div class="form-group" v-if="permissionAdd">
    <label for="exampleInputEmail">Дополнительное разрешение</label>
    <a-select
      show-search
      v-model="newUser.permission"
      placeholder="Выберите Офиса"
      :class="{'is-invalid': validateErrors.permission}"
      :default-active-first-option="false"
      style="width: 100%"
      :show-arrow="false"
      :filter-option="false"
      :not-found-content="null"
    >
      <a-select-option v-for="d in permissions" :key="d.value">
        {{ d.title }}
      </a-select-option>
    </a-select>
    <div class="invalid-feedback" v-for="value in validateErrors.branchoffice_id"> {{value}} </div>
  </div>


  <div class="form-group">
    <label for="exampleInputEmail">Страна</label>
    <a-select
      show-search
      v-model="newUser.country_id"
      placeholder="Выберите страну"
      :class="{'is-invalid': validateErrors.country_id}"
      :default-active-first-option="false"
      style="width: 100%"
      :show-arrow="false"
      :filter-option="false"
      :not-found-content="null"
      @search="searchSingleCountry"
      @change="changeCountryId"
    >
      <a-select-option v-for="d in dataSourceCountries" :key="d.id">
        {{ d.title }}
      </a-select-option>
    </a-select>
    <div class="invalid-feedback" v-for="value in validateErrors.country_id"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Область (Регион)</label>
    <a-select
      show-search
      v-model="newUser.region_id"
      placeholder="Выберите региона"
      :class="{'is-invalid': validateErrors.region_id}"
      :default-active-first-option="false"
      style="width: 100%"
      :show-arrow="false"
      :filter-option="false"
      :not-found-content="null"
      @search="searchSingleRegion"
      @change="changeRegionId"
    >
      <a-select-option v-for="d in dataSourceRegions" :key="d.id">
        {{ d.title }}
      </a-select-option>
    </a-select>
    <div class="invalid-feedback" v-for="value in validateErrors.region_id"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Район или Город</label>
    <a-select
      show-search
      v-model="newUser.district_id"
      placeholder="Выберите города"
      :class="{'is-invalid': validateErrors.district_id}"
      :default-active-first-option="false"
      style="width: 100%"
      :show-arrow="false"
      :filter-option="false"
      :not-found-content="null"
      @search="searchSingleCity"
      @change="changeDistrictId"
    >
      <a-select-option v-for="d in dataSourceCities" :key="d.id">
        {{ d.title }}
      </a-select-option>
    </a-select>
    <div class="invalid-feedback" v-for="value in validateErrors.district_id"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Офис</label>
    <a-select
      show-search
      v-model="newUser.branchoffice_id"
      placeholder="Выберите Офиса"
      :class="{'is-invalid': validateErrors.branchoffice_id}"
      :default-active-first-option="false"
      style="width: 100%"
      :show-arrow="false"
      :filter-option="false"
      :not-found-content="null"
      @search="searchSingleBranch"
    >
      <a-select-option v-for="d in dataSourceBranches" :key="d.id">
        {{ d.title }}
      </a-select-option>
    </a-select>
    <div class="invalid-feedback" v-for="value in validateErrors.branchoffice_id"> {{value}} </div>
  </div>

  <div class="form-group">
    <label for="exampleInputEmail">Статус пользователя <span :style="{color: status == 'Активный' ? '#2EB85C' : 'red'}" >({{status}})</span> </label>
    <a-form-item>
      <a-switch v-decorator="['switch', { valuePropName: 'active' }]" @change="changeSwitchItem" />
    </a-form-item>
    <div class="invalid-feedback" v-for="value in validateErrors.status"> {{value}} </div>
  </div>

  <div class="form-group">
    <label for="exampleInputEmail">Удалить подтверждение заказа <span :style="{color: delete_signature == true ? '#2EB85C' : 'red'}" >({{delete_signature}})</span> </label>
    <a-form-item>
      <a-switch v-decorator="['switch', { valuePropName: true }]" @change="changeSwitchItemDeleteSignature" />
    </a-form-item>
    <div class="invalid-feedback" v-for="value in validateErrors.status"> {{value}} </div>
  </div>

  <div :class="{'is-invalid': validateErrors.image}" class="mb-3">
    <div v-if="!file">
        <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
          <div class="dropZone-info" @drag="onChange" >
            <span class="fa fa-cloud-upload dropZone-title"></span>
            <span class="dropZone-title">Перетащите файл сюда</span>
            <div class="dropZone-upload-limit-info">
              <div>Поддерживаемые расширения: png, jpg, jpeg, bmp</div>
              <div>Максимальный размер изображения: 5МБ</div>
            </div>
          </div>
          <input type="file" @change="onChange">
        </div>
      </div>
      <div v-else class="dropZone-uploaded">
        <div class="dropZone-uploaded-info">
          <span class="dropZone-title">Uploaded</span>
          <button type="button" class="btn btn-primary removeFile" @click="removeFile">Remove File</button>
        </div>
      </div>
  </div>
  <div class="invalid-feedback" v-for="value in validateErrors.image"> {{value}} </div>

  <div class="d-flex">
    <button type="submit" class="ml-auto btn btn-primary" @click="storeItem">Добавить</button>
  </div>
</a-modal>

<!-- Edit modal -->
<a-modal v-model="visibleEditModal" title="Изменить данные пользователя"  footer="">
  <div class="form-group">
    <label for="exampleInputEmail">Имя Фамилия </label>
    <input type="text" class="form-control" v-model="user.name" :class="{'is-invalid': validateErrors.name}" placeholder="Имя">
    <div class="invalid-feedback" v-for="value in validateErrors.name"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Email </label>
    <input type="text" class="form-control" v-model="user.email" :class="{'is-invalid': validateErrors.email}" placeholder="Email">
    <div class="invalid-feedback" v-for="value in validateErrors.email"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Телефон </label>
    <input type="text" class="form-control" v-model="user.phone" :class="{'is-invalid': validateErrors.phone}" placeholder="Телефон">
    <div class="invalid-feedback" v-for="value in validateErrors.phone"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Пароль</label>
    <a-form-item :class="{'is-invalid': validateErrors.password}" >
      <a-input-password :class="{'is-invalid': validateErrors.password}" v-model="user.password" allow-clear placeholder="Мин 6 символов" />
    </a-form-item>
    <div class="invalid-feedback" v-for="value in validateErrors.password"> {{value}} </div>
  </div>

  <hr>

  <div v-if="editRole">
    <div class="form-group">
      <label for="exampleInputEmail">Роль</label>
      <a-select
        show-search
        v-model="user.role"
        placeholder="Роль"
        :class="{'is-invalid': validateErrors.role}"
        :default-active-first-option="false"
        style="width: 100%"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        @change="changeRoleOnEdit"
      >
        <a-select-option v-for="d in roles" :key="d.value">
          {{ d.title }}
        </a-select-option>
      </a-select>
      <div class="invalid-feedback" v-for="value in validateErrors.role"> {{value}} </div>
    </div>
    <div class="form-group" v-if="permissionAdd">
      <label for="exampleInputEmail">Дополнительное разрешение</label>
      <a-select
        show-search
        v-model="user.permission"
        placeholder="Выберите Офиса"
        :class="{'is-invalid': validateErrors.permission}"
        :default-active-first-option="false"
        style="width: 100%"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
      >
        <a-select-option v-for="d in permissions" :key="d.value">
          {{ d.title }}
        </a-select-option>
      </a-select>
      <div class="invalid-feedback" v-for="value in validateErrors.branchoffice_id"> {{value}} </div>
    </div>
  </div>
  <div v-if="!editRole">
    <div class="form-group" v-if="user.roles">
      <label for="exampleInputEmail">Роль </label>
      <div class="d-flex" v-if="user.roles.length">
        <input type="text" v-if="user.roles[0].name == 'admin'" class="form-control" value="Админ" disabled>
        <input type="text" v-if="user.roles[0].name == 'moderator'" class="form-control" value="Модератор" disabled>
        <input type="text" v-if="user.roles[0].name == 'operation'" class="form-control" value="Оператор" disabled>
        <input type="text" v-if="user.roles[0].name == 'driver'" class="form-control" value="Водитель" disabled>
        <button class="btn btn-info" @click="editRole=true; user.role=''; user.permission=''">Изменить</button>
      </div>
    </div>
    <div class="form-group" v-if="user.permissions">
      <div v-if="user.permissions.length">
        <label for="exampleInputEmail">Дополнительное разрешение </label>
        <input type="text" v-if="user.permissions[0].name == 'super_admin'" class="form-control" value="Супер администратор со всеми возможностями" disabled>
        <input type="text" v-if="user.permissions[0].name == 'moderator'" class="form-control" value="Модератор с больше возмоностями" disabled>
      </div>

    </div>
  </div>

  <div v-if="editBranch">
    <div class="form-group">
      <label for="exampleInputEmail">Страна</label>
      <a-select
        show-search
        v-model="user.country_id"
        placeholder="Выберите страну"
        :class="{'is-invalid': validateErrors.country_id}"
        :default-active-first-option="false"
        style="width: 100%"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        @search="searchSingleCountry"
        @change="changeCountryId"
      >
        <a-select-option v-for="d in dataSourceCountries" :key="d.id">
          {{ d.title }}
        </a-select-option>
      </a-select>
      <div class="invalid-feedback" v-for="value in validateErrors.country_id"> {{value}} </div>
    </div>
    <div class="form-group">
      <label for="exampleInputEmail">Область (Регион)</label>
      <a-select
        show-search
        v-model="user.region_id"
        placeholder="Выберите региона"
        :class="{'is-invalid': validateErrors.region_id}"
        :default-active-first-option="false"
        style="width: 100%"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        @search="searchSingleRegion"
        @change="changeRegionId"
      >
        <a-select-option v-for="d in dataSourceRegions" :key="d.id">
          {{ d.title }}
        </a-select-option>
      </a-select>
      <div class="invalid-feedback" v-for="value in validateErrors.region_id"> {{value}} </div>
    </div>
    <div class="form-group">
      <label for="exampleInputEmail">Район или Город</label>
      <a-select
        show-search
        v-model="user.district_id"
        placeholder="Выберите города"
        :class="{'is-invalid': validateErrors.district_id}"
        :default-active-first-option="false"
        style="width: 100%"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        @search="searchSingleCity"
        @change="changeDistrictId"
      >
        <a-select-option v-for="d in dataSourceCities" :key="d.id">
          {{ d.title }}
        </a-select-option>
      </a-select>
      <div class="invalid-feedback" v-for="value in validateErrors.district_id"> {{value}} </div>
    </div>
    <div class="form-group">
      <label for="exampleInputEmail">Офис</label>
      <a-select
        show-search
        v-model="user.branchoffice_id"
        placeholder="Выберите Офиса"
        :class="{'is-invalid': validateErrors.branchoffice_id}"
        :default-active-first-option="false"
        style="width: 100%"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        @search="searchSingleBranch"
      >
        <a-select-option v-for="d in dataSourceBranches" :key="d.id">
          {{ d.title }}
        </a-select-option>
      </a-select>
      <div class="invalid-feedback" v-for="value in validateErrors.branchoffice_id"> {{value}} </div>
    </div>
  </div>
  <div v-if="!editBranch">
    <div class="form-group" v-if="user.country">
      <label for="exampleInputEmail">Страна </label>
      <input type="text" class="form-control" v-model="user.country.title" disabled>
    </div>
    <div class="form-group" v-if="user.region">
      <label for="exampleInputEmail">Область </label>
      <input type="text" class="form-control" v-model="user.region.title" disabled>
    </div>
    <div class="form-group" v-if="user.district">
      <label for="exampleInputEmail">Район или город </label>
      <input type="text" class="form-control" v-model="user.district.title" disabled>
    </div>
    <div class="form-group">
      <label for="exampleInputEmail">Офис </label>
      <div class="d-flex">
        <input type="text" v-if="user.branch" class="form-control" v-model="user.branch.title" disabled>
        <button class="btn btn-info" @click="editBranch=true; user.country_id=''; user.region_id=''; user.district_id=''; user.branchoffice_id=''">Изменить</button>
      </div>
    </div>

  </div>

  <div class="form-group">
    <label for="exampleInputEmail">Статус пользователя <span :style="{color: status == 'Активный' ? '#2EB85C' : 'red'}" >({{status}})</span> </label>
    <div v-if="user.status == 'active'">
      <div>
        <a-switch default-checked @change="changeSwitchItem" />
      </div>
    </div>
    <div v-else>
      <a-form-item>
        <a-switch v-decorator="['switch', { valuePropName: 'active' }]" @change="changeSwitchItem" />
      </a-form-item>
    </div>
    <div class="invalid-feedback" v-for="value in validateErrors.status"> {{value}} </div>
  </div>

  <div class="form-group">
    <label for="exampleInputEmail">Удалить подтверждение заказа <span :style="{color: delete_signature == true ? '#2EB85C' : 'red'}" >({{delete_signature}})</span> </label>
    <a-form-item>
      <a-switch v-model="user.delete_signature" v-decorator="['switch', { valuePropName: true }]" @change="changeSwitchItemDeleteSignature" />
    </a-form-item>
    <div class="invalid-feedback" v-for="value in validateErrors.status"> {{value}} </div>
  </div>
  
  <div class="clearfix" :class="{'is-invalid': validateErrors.image}">
     <a-upload
       :action="$store.state.urlBackend + '/api/users/' + user.hash + '/image/upload'"
       :headers='{"Authorization": `Bearer ` + access_token}'
       list-type="picture-card"
       :file-list="fileList"
       :before-upload="beforeUpload"
       @preview="handlePreview"
       @change="imageUploadFunction"
     >
       <div v-if="fileList.length < 1">
         <a-icon type="plus" />
         <div class="ant-upload-text">
           Upload
         </div>
       </div>
     </a-upload>
     <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
       <img alt="example" style="width: 100%" :src="previewImage" />
     </a-modal>
   </div>
  <div class="d-flex">
    <button type="submit" class="ml-auto btn btn-primary" @click="updateItem">Изменить</button>
  </div>
</a-modal>

<!-- Delete modal -->
<a-modal v-model="visibleDeleteModal" title="Подтверждение на удаление" ok-text="Удалить" cancel-text="Отменить" @ok="destroyItem">
  <h6 style="color: red">Точно хотите безвозвратно удалить ?</h6>
</a-modal>
</div>


</template>

<script>
import axios from 'axios'
import moment, { Moment } from 'moment';
const data = '';
export default {
  name : 'User',
  data() {
    return {
      data,

      file: '',
      dragging: false,
      previewVisible: false,
      previewImage: '',
      access_token: localStorage.token,
      fileList: [
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
      ],

      newUser: {
        name: '',
        email: '',
        phone: '',
        password: '',
        password_confirmation: '',
        role: '',
        permission: '',
        country_id: '',
        region_id: '',
        district_id: '',
        branchoffice_id: '',
        status: 'active',
        image: '',
        delete_signature: false
      },
      validateErrors: [],
      user: [],

      me: [],

      currentPage: 1,
      totalResults: 0,
      scrolled: false,
      old_height: 0,
      last_page: 1,
      status: 'Неактивный',
      delete_signature: false,

      dataSourceCountries: [],
      dataSourceRegions: [],
      dataSourceCities: [],
      dataSourceBranches: [],

      roles: [

      ],
      permissions: [

      ],

      dataFilterSingle: {
        countries: [],
        regions: [],
        districts: []
      },

      load: true,
      visibleShowModal: false,
      visibleEditModal: false,
      visibleCreateModal: false,
      visibleDeleteModal: false,
      editCountryRegionCity: false,

      permissionAdd: false,
      editRole: false,
      editBranch: false
    };
  },
  mounted: function(){
    axios.get('/user').then((response) => {
      if(response.status == 200){
        this.me = response.data;
        if(this.me.permissions.length && this.me.roles.length){ console.log(this.permissions);
          if(this.me.roles[0].name == 'admin' && this.me.permissions[0].name == 'super_admin'){
            this.roles = [
              {
                title: "Водитель",
                value: "driver"
              },
              {
                title: "Оператор",
                value: "operation"
              },
              {
                title: "Модератор",
                value: "moderator"
              },
              {
                title: "Админ",
                value: "admin"
              }
            ];
            this.permissions =  [{
              title: "Модератор с больше возможностями",
              value: "moderator"
            }];
          }else if(this.me.roles[0].name == 'admin' && this.me.permissions[0].name == 'admin'){
            this.roles = [
              {
                title: "Водитель",
                value: "driver"
              },
              {
                title: "Оператор",
                value: "operation"
              },
              {
                title: "Модератор",
                value: "moderator"
              }
            ];
            this.permissions =  [{
              title: "Модератор с больше возможностями",
              value: "moderator"
            }];
          }else if(this.me.roles[0].name == 'moderator' && this.me.permissions[0].name == 'moderator'){
            this.roles = [
              {
                title: "Водитель",
                value: "driver"
              },
              {
                title: "Оператор",
                value: "operation"
              },
              {
                title: "Модератор",
                value: "moderator"
              }
            ];
            this.permissions =  [];
          }else if(this.me.roles[0].name == 'moderator' && this.me.permissions[0].name == 'moder'){
            this.roles = [
              {
                title: "Водитель",
                value: "driver"
              },
              {
                title: "Оператор",
                value: "operation"
              }
            ];
            this.permissions =  [];
          }
        }else if(!this.me.permissions.length && this.me.roles.length){
          if(this.me.roles[0].name == 'admin'){
            this.roles = [
              {
                title: "Водитель",
                value: "driver"
              },
              {
                title: "Оператор",
                value: "operation"
              },
              {
                title: "Модератор",
                value: "moderator"
              }
            ];
            this.permissions =  [{
              title: "Модератор с больше возможностями",
              value: "moderator"
            }];
          }else if(this.me.roles[0].name == 'moderator'){
            this.roles = [
              {
                title: "Водитель",
                value: "driver"
              },
              {
                title: "Оператор",
                value: "operation"
              }
            ];
            this.permissions =  [];
          }
        }
      }
    }).catch((error) => {
      console.log(error)
    })

   axios.get('/users').then((response)=>{
     if(response.status == 200){
       this.data = response.data.data;
       this.totalResults = response.data.total;
       this.load = false;
       console.log(this.data)
       this.last_page = response.data.last_page;
     }else if(response.status == 204){
       this.load = false;
       this.$message.error(
             'Городов не найдено',
             3,
       );
     }
    }).catch((error)=>{
      if(error.response.status===401){
          this.$router.push('/pages/login')
          this.load = false;
      }else if(error.response.status == 403){
        this.$message.error(
              error.response.data.message,
              3,
        );
        this.load = false;
      }else if(error.response.status == 500){
        this.load = false;
        this.$message.error(
              'Ошибка сервера',
              3,
        );

      }
    });

    window.onscroll = () => {
        let height = document.getElementById('usersList').clientHeight
        if ((window.innerHeight + document.documentElement.scrollTop) >= height) {
          if(this.old_height!=height){
            this.old_height = height;
            let limit = this.data.length/this.$store.state.paginateCount + 1;
            if(limit <= this.last_page){
              this.load = true;
              axios.get('/users', {params: {page: limit}}).then(response => {
                if(response.status == 200){
                    this.totalResults = response.data.total;
                    this.data = this.data.concat(response.data.data);
                    this.load = false
                }else if(response.status == 204){
                  this.load = false;
                  this.$message.error(
                    'Районов не найдено',
                    3
                  );
                }
              }).catch(error => {
                if(error.response.status == 500){
                  this.load = false;
                  this.$message.error(
                    'Ошибка сервера',
                    3
                  );
                }else if(error.response.status == 204){
                  this.load = false;
                  this.$message.error(
                    'Районов не найдено',
                    3
                  );
                }else{
                  this.load = false
                }
              });
            }
          }
        }
    };


  },
  methods: {
    createdAt(date){
      let d = new Date(date).toLocaleDateString();
      return moment(date).format('DD/MM/YYYY HH:mm');
    },

    changeRoleOnEdit(){
      console.log(this.user)
      if(this.user.role == 'moderator'){
        this.permissionAdd = true;
      }else{
        this.permissionAdd = false;
      }
    },
    changeRole(){
      if(this.newUser.role == 'moderator'){
        this.permissionAdd = true;
      }else{
        this.permissionAdd = false;
      }
    },

    handleCancel() {
      this.previewVisible = false;
    },
    handlePreview(file) {
      // if (!file.url && !file.preview) {
      //   file.preview = await getBase64(file.originFileObj);
      // }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/bmp' || file.type === 'image/jpg';
      if (!isJpgOrPng) {
        this.$message.error('Пожалуйста выберите (png, bmp, jpg, jpeg)!', 3);
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('Размер не должен превышать 2 мб!', 3);
      }
      return isJpgOrPng && isLt2M;
    },
    imageUploadFunction({ fileList }) {
      if(!fileList.length){
        axios.delete('/users/image/delete/'+this.user.hash).then(response => {
          if(response.status == 200){
            this.$message.success(response.data.success, 3);

            this.user.image = '';
          }else{
            this.$message.error(
              response.data.error,
              3
            );
          }
        }).catch(error => {
          console.log(error);
          if(error.response.status == 500){
            this.$message.error(
              error.response.data.error,
              3
            );
          }
        }).finally(() => {
          this.fileList = fileList;
        });
      }else{
        console.log(fileList)
        this.fileList = fileList;
        if(fileList[0].response){
          this.user.image = fileList[0].response
        }
        // let formData = new FormData();
        // formData.append('image', fileList);
        // console.log(formData);
        // axios.post('/typeofcargo/image/upload/' + this.user.id, formData, {
        //   headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': `multipart/form-data`,
        //   }})
        // .then(response => {
        //   console.log(response)
        //   if(response.status == 201){
        //     this.$message.success(
        //       'Успешно изменено',
        //       3,
        //     );
        //   }
        // }).catch(error => {
        //   if(error.response.status == 500){
        //     this.$message.error(
        //       'Ошибка сервера',
        //       3
        //     );
        //   }else if(error.response.status == 422){
        //     this.validateErrors = error.response.data.errors
        //     this.$message.error(
        //       error.response.data.message,
        //       3
        //     )
        //   }
        // });
      }

    },
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.dragging = false;
        return;
      }
      this.createFile(files[0]);
    },
    createFile(file) {
      if (!file.type.match('image.*')) {
        alert('please select txt file');
        this.dragging = false;
        return;
      }
      if (file.size > 2048000) {
        this.$message.error(
          'Пожалуйста выберите файла размером меньше 2МБ',
          3
        )
        this.dragging = false;
        return;
      }
      this.file = file;
      this.newUser.image = file;
      this.dragging = false;
      console.log(this.newUser);
    },
    removeFile() {
      this.file = '';
    },

    updateItem(){
      this.load = true;
      axios.put('/users/edit', this.user)
      .then(response => {
        if(response.status == 200){
          this.load = false;
          this.visibleEditModal = false
          this.$message.success(
                'Успешно изменено',
                3,
          );
        }else if(response.status == 404){
          this.$message.error(
                response.data.error,
                3,
          );
          this.load = false;
        }
      }).catch(error => {
        if(error.response.status == 422){
          this.load = false;
          this.$message.error(
                error.response.data.message,
                3,
          );
          this.validateErrors = error.response.data.errors
        }else if(error.response.status == 404){
          this.load = false;
          this.$message.error(
                error.response.data.error,
                3,
          );
          this.load = false;
        }else if(error.response.status == 500){
          this.$message.error(
                'Ошибка сервера',
                3,
          );
          this.load = false;
        }else if(error.response.status == 400){ console.log(error.response)
          this.$message.error(
            error.response.data.error,
            3
          );
          this.load = false;
        }
      });
    },
    storeItem(){
      let formData = new FormData();
      formData.append('image', this.file);
      formData.append('name', this.newUser.name);
      formData.append('email', this.newUser.email);
      formData.append('phone', this.newUser.phone);
      formData.append('password', this.newUser.password);
      formData.append('password_confirmation', this.newUser.password_confirmation);
      formData.append('role', this.newUser.role);
      formData.append('permission', this.newUser.permission);
      formData.append('country_id', this.newUser.country_id);
      formData.append('region_id', this.newUser.region_id);
      formData.append('district_id', this.newUser.district_id);
      formData.append('branchoffice_id', this.newUser.branchoffice_id);
      formData.append('status', this.newUser.status);

      this.load = true;
      axios.post('/users/create', formData, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': `multipart/form-data`,
        }})
      .then(response => {
        if(response.status == 201){
          this.visibleCreateModal = false;
          this.load = false;
          this.newUser.name = '';
          this.data = this.data.concat(response.data)
          this.totalResults += 1;
          this.$message.success(
                'Успешно добавлено',
                3,
          );
        }else{
          this.load = false;
        }
      }).catch(error => {
        if(error.response.status == 500){
          this.load = false;
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }else if(error.response.status == 422){
          this.validateErrors = error.response.data.errors
          this.load = false
          this.$message.error(
            error.response.data.message,
            3
          )
        }
      });
    },
    destroyItem(){
      axios.delete('/users/delete/'+this.user.hash).then(response => {
         if(response.status==200){
          this.$message.success(
                response.data.success,
                3,
          );
          this.data.splice(this.index, 1)
          this.totalResults--;
        }
      }).catch(error => {
        if(error.response.status==400){
          this.$message.error(
                error.response.data.error,
                3,
          );
        }else if(error.response.status == 403){
          this.$message.error(
                error.response.data.message,
                3,
          );
        }else if(error.response.status == 404){
          this.$message.error(
                error.response.data.error,
                3,
          );
        }else if(error.response.status == 500){
          this.$message.error(
                'Ошибка сервера',
                3,
          );
        }
      }).finally(() => {
          this.visibleDeleteModal = false
      });
    },

    changeSwitchItem(value){
      this.newUser.status = value ? 'active' : 'inactive';
      this.user.status = value ? 'active' : 'inactive';
      this.status = value ? 'Активный' : 'Неактивный';
      console.log(this.user);
    },
    changeSwitchItemDeleteSignature(value){
      this.delete_signature = value;
      console.log(this.newUser.delete_signature);
      this.newUser.delete_signature = value;
      this.user.delete_signature = value;
      this.delete_signature == true ? 'Активный' : 'Неактивный';
    },
    createItem(){
      this.visibleCreateModal = true
    },
    showItem(val) {
      this.user = val;
      this.visibleShowModal = true;
    },
    editItem(val){
      this.fileList = [];
      if(val.image){
        let imageItem = [];
        imageItem.uid = val.id;
        imageItem.name = 'image.png';
        imageItem.status = 'done';
        imageItem.url = this.$store.state.urlBackend + '/' + val.image;
        this.fileList[0] = imageItem;
      }

      this.user = val;
      console.log(this.user)
      this.visibleEditModal = true;
      this.editRole = false;
      this.editBranch = false;
      if(this.user.status == 'active'){
        this.status = 'Активный';
      }else{
        this.status = 'Неактивный';
      }
    },
    deleteItem(index, value){
      this.index = index;
      this.user = value;
      this.visibleDeleteModal = true;
    },

    changeCountryId(value){
      this.dataFilterSingle.countries[0] = value;
    },
    changeRegionId(value){
      this.dataFilterSingle.regions[0] = value;
    },
    changeDistrictId(value){
      this.dataFilterSingle.districts[0] = value;
    },

    searchSingleCountry(value){
      console.log(value)
      if(value.length > 1){
        axios.get('/international/countries/autocomplete/search', {params: {query: value}})
        .then(response => {
          if(response.status == 200){
            this.dataSourceCountries = response.data;
          }else if(response.status == 204){
            this.$message.error(
              'Нет соответствующих стран',
              3
            )
          }
        }).catch(error => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }else{
            this.$message.error(
              'Нет соответствующих стран',
              3
            )
          }
        });
      }
    },
    searchSingleRegion(value){
      axios.post('/international/regions/autocomplete/search', this.dataFilterSingle, {params: {query: value}})
      .then(response => {
        if(response.status == 200){
          this.dataSourceRegions = response.data;
        }else if(response.status == 204){
          this.$message.error(
            'Нет соответствующего региона',
            3
          )
        }
      }).catch(error => {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }else{
          this.$message.error(
            'Нет соответствующего региона',
            3
          )
        }
      });
    },
    searchSingleCity(value){
      axios.post('/international/cities/autocomplete/search', this.dataFilterSingle, {params: {query: value}})
      .then(response => {
        if(response.status == 200){
          this.dataSourceCities = response.data;
        }else if(response.status == 204){
          this.$message.error(
            'Нет соответствующего города',
            3
          )
        }
      }).catch(error => {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }else{
          this.$message.error(
            'Нет соответствующего города',
            3
          )
        }
      });
    },
    searchSingleBranch(value){
      let url = '';
      if(this.newUser.country_id == 192 || this.user.country_id == 192){
        url = '/branches/local/autocomplete/search/with/paginate'
      }else{
        url = '/branches/international/autocomplete/search/with/paginate'
      }
      axios.post(url, this.dataFilterSingle, {params: {query: value}})
      .then(response => {
        if(response.status == 200){
          console.log(response.data.data)
          this.dataSourceBranches = response.data.data;
        }else if(response.status == 204){
          this.$message.error(
            'Нет соответствующего офиса',
            3
          )
        }
      }).catch(error => {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }else{
          this.$message.error(
            'Нет соответствующего офиса',
            3
          )
        }
      });
    },

  },
}
</script>
<style>
   .ant-modal{
     width: 800px !important;
   }
  .ant-modal-mask{
    z-index: 2000 !important;
  }
  .ant-modal-wrap{
    z-index: 2000 !important;
  }
  .my-img-circle{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #e9e9e9
  }


  .dropZone {
  width: 100%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone:hover {
  border: 2px dotted #2e94c4;
}

.dropZone:hover .dropZone-title {
  color: #1975A0;
}

.dropZone-info {
  color: #A8A8A8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5C5C5C;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #A8A8A8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>
